.sponsors {
  background-color: #071013;
  padding: 30px;

  .header-underline {
    height: 3px;
    width: 50px !important;
    background-color: $primary-orange;
    // margin-left: auto;
    margin: 0 auto;
    transform: translateY(-30px) translateX(55px);
  }
}

.sponsors h2 {
  color: #ffffff;
  font-size: 2.5rem;
  padding: 1rem;
  width: 100%;
  top: 75px;
  z-index: 999;
  text-align: center;
}

.grid-container {
  margin-top: 50px;
  margin: auto;
  max-width: 900px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1px;
  background-color: #4f4949;
  border: 1px solid #4f4949;
}

.grid-container div {
  background-color: #071013;
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  border-color: #071013;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }

  .grid-img {
    width: 70%;
    max-width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    opacity: 0.7;
    transition: 0.1s ease-in-out all;
  }
}

.grid-item:nth-child(3) .grid-img,
.grid-item:nth-child(4) .grid-img,
.grid-item:nth-child(8) .grid-img {
  transform: scale(0.8);

  @media only screen and (max-width: 768px) {
    margin: 0;
    width: 50px;
    transform: scale(0.8);
  }
}
.grid-item:nth-child(19) .grid-img {
  width: 70px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    width: 50px;
    transform: scale(0.6);
  }
  height: auto;
}

.grid-item:hover {
  transition: ease-in-out 0.01s;
  background: linear-gradient(
    180deg,
    rgba(218, 80, 44, 0.93) 0%,
    rgba(217, 107, 49, 0.93) 39.58%,
    rgba(216, 149, 56, 0.93) 100%
  );
  box-shadow: 0px 0px 15px 5px #ff6f28;
  z-index: 10;
}

.grid-item:hover .grid-img {
  opacity: 1;
}

.grid-item p {
  color: #ffffff;
  font-size: 1.5rem;
  margin: auto;

  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}
