* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer-container {
  max-width: 1200px;
  margin: auto;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

ul {
  list-style: none;
}

.footer-coll {
  width: 25%;
  padding: 0 15px;
}

.footer {
  // margin-top: 50px;
  background-color: #071013;
  padding: 4rem 2rem;
}

.footer-coll h4 {
  font-size: 20px;
  margin-bottom: 20px;
  position: relative;
  color: #ffffff;
}

.footer-coll h4::before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -2px;
  background-color: #fa8a13;
  height: 2px;
  box-sizing: border-box;
  width: 20px;
}

.footer-coll ul li {
  color: #bbbbbb;
  transition: all 0.3s ease;
  display: block;

  a {
    text-decoration: none;
    color: #bbbbbb;
    transition: all 0.3s ease;
    display: block;
  }
}

.footer-coll ul li:hover {
  color: #ffffff;
  padding-left: 10px;

  a:hover{
    color: #ffffff;
  padding-left: 10px;
  }
}

.social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.social-links a:hover {
  color: #071013;
  background-color: #ffffff;
}

@media (max-width: 900px) {
  .footer-coll {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 574px) {
  .footer-coll {
    width: 100%;
    margin-bottom: 30px;
  }
}
