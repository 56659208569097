@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
// Define all constants here

$font-family: Helvetica, sans-serif;
$oswald: "Oswald", sans-serif;
$montserrat: "Montserrat", sans-serif;
$secondary-black: #151515;
$primary-orange: #fa8a13;
$primary-black: #0b0b0b;
$very-dark-desaturated-blue: hsl(238, 29%, 16%);
$soft-red: hsl(14, 88%, 65%);

$soft-violet: hsl(273, 75%, 66%);
$soft-blue: hsl(240, 73%, 65%);

$very-dark-grayish-blue: hsl(237, 12%, 33%);
$dark-grayish-blue: hsl(240, 6%, 50%);

$light-grayish-blue: hsl(240, 5%, 91%);
