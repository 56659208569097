.navbar-container {
  background-color: #071013;
  width: 100%;
  z-index: 3000;
  display: flex;
  color: #fff;
  height: auto;
  padding: 0.2rem 1rem;
  height: 75px;
  position: fixed;
  align-items: center;

  @media only screen and (max-width: 768px) {
    // flex-direction: column;
    padding: 0.4rem 1rem;
    z-index: 3000;
    justify-content: space-between;
  }

  .manipal-logos {
    display: flex;
    width: 100%;
    transition: all linear 5s;
    img {
      height: 40px;
      width: auto;
      margin-right: 1rem;
    }
  }
  
  .manas-logo-hidden {
    display: none;
    transition: all linear 5s;
  }
  .manas-logo {
    background-image: url("../assets/Logos/manas-with-border.png");
    width: 100%;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all linear 5s;
    

    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      width: 64px;
      margin: 0 0.5rem;
    }
  }

  .navbar-links {
    display: flex;
    margin-left: auto;
    transition: 0.5s ease-in-out all;
    width: 100%;
    z-index: 1000;

    @media only screen and (max-width: 768px) {
      display: block;
      width: 100%;
      height: 100%;
      transform: translate(-300%, 0);
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
    }

    .navbar-link {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Poppins";
      width: 100%;
      white-space: nowrap;
      padding: 1rem 1.4rem;
      font-size: 1rem;
      transition: 0.2s ease-in-out all;

      &:hover {
        color: $primary-orange;
        transform: translateY(-2px);
      }

      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
      }
    }
  }

  .navbar-open {
    transition: 0.5s ease-in-out all;
    // display: none;

    @media only screen and (max-width: 768px) {
      display: block;
      transform: translate(0, 75px);
      background-color: #071013;
      z-index: 2000;
      width: 100%;
      height: 100vh;
      position: fixed;

      .navbar-link {
        width: 100%;
        transition: 0.2s ease-in-out all;

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.1);
        }
      }
    }
  }

  .hamburger {
    display: flex;
    padding: 0 1.4rem;
    font-size: 2.4rem;
    display: none;
    z-index: 1000;
    transition: 0.4s ease-in-out all;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  /* Icon 4 */

  #nav-icon {
    margin-top: 8px;
    width: 45px;
    height: 60px;
    position: relative;
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
    transform: rotate(0deg) scale(0.8);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  #nav-icon span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.4s ease-in-out;
    -moz-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon span:nth-child(2) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon span:nth-child(3) {
    top: 36px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 8px;
  }

  #nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 34px;
    left: 8px;
  }
}
