.faq-container {
  margin: 0;
  padding: 0;
  background: linear-gradient(#db5b39, #f49221, #f49221);
  color: $dark-grayish-blue;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  min-height: 100vh;
  display: grid;
  place-items: center;

  h1 {
    color: $very-dark-desaturated-blue;
    font-size: 32px;
    line-height: 32px;
  }
  button {
    color: $very-dark-grayish-blue;
    font-size: 14px;
    line-height: 14px;
  }
  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button:focus-visible {
    outline: 2px dashed $soft-red;
    outline-offset: 0px;
  }

  .font-weight-bold {
    font-weight: 700;
  }
  .component {
    margin: 0 auto;
    max-width: 920px;
    margin-top: 75px;
    width: 100%;
    background-color: #ffffff;
    background-image: url(../assets/FAQ/bg-pattern-desktop.svg);
    background-position: -565px -295px;
    background-repeat: no-repeat;
    box-shadow: 0px 50px 50px -20px rgba(53, 18, 122, 0.497159);
    border-radius: 23px;
    display: flex;
  }
  .faq__desc.show-description {
    height: 2rem;
    margin-bottom: 15px;
  }
  .illustration {
    width: 100%;
    position: relative;

    &__box {
      position: absolute;
      z-index: 999;
      top: 200px;
      left: -95px;
    }

    &__woman-desktop {
      position: absolute;
      top: 60px;
      left: -77px;
      clip-path: inset(0px 0px 0px 77px);
    }

    &__woman-mobile {
      display: none;
    }
  }

  .attribution {
    font-size: 11px;
    text-align: center;
    color: $light-grayish-blue;
    padding-top: 10px;
  }
  .attribution a {
    color: $soft-violet;
  }

  .faq {
    width: 100%;
    padding-top: 65px;
    padding-bottom: 80px;

    &__title {
      margin: 0;
      padding-bottom: 25px;
    }

    &__question {
      max-width: 350px;
      width: 100%;
      border-bottom: 1px solid $light-grayish-blue;
    }

    &__question-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;
      padding-top: 18px;
      padding-bottom: 18px;
      text-align: left;
      &::after {
        content: url(../assets/FAQ/icon-arrow-down.svg);
        margin-right: 17px;
        transform: rotate(90deg);
      }
      &:hover {
        color: $soft-red;
      }
    }
    &__desc {
      margin-left: 0px;
      padding-right: 17px;
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
      overflow: hidden;
      transition: all 200ms ease-out;
    }
  }
}

@media only screen and (max-width: 800px) {
  .illustration__woman-desktop {
    max-width: 120%;
  }
}

@media only screen and (max-width: 700px) {
  .faq-container {
    padding-top: 105px;

    .component {
      max-width: 327px;
      background-image: url(../assets/FAQ/bg-pattern-mobile.svg);
      background-position: top;
      flex-direction: column;
    }

    .illustration {
      &__box {
        display: none;
      }

      &__woman-desktop {
        display: none;
      }

      &__woman-mobile {
        display: block;
        margin: 0 auto;
        position: absolute;
        top: -105px;
        left: 12px;
      }
    }
    .faq {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 132px;
      padding-bottom: 48px;

      &__question {
        max-width: 279px;
      }

      &__question-button {
        width: 279px;
        font-size: 13px;
        line-height: 13px;

        &::after {
          padding-right: 0px;
        }
      }
    }
  }
}
