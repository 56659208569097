.projects {
  height: auto;
  margin: 0;
  background: #071013;

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  h2 {
    color: white;
    text-align: center;
    font-size: 2.4rem;
    font-family: "Poppins";
  }
  .header-underline {
    height: 3px;
    width: 50px !important;
    background-color: $primary-orange;
    // margin-left: auto;
    margin: 0 auto;
    transform: translateY(-30px) translateX(55px);
  }
}

.projects-wrapper {
  margin: 0;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  h1{
    color: red;
  }
}

.module-border-wrap {
  width: 27%;
  padding: 1rem;
  position: relative;
  background: linear-gradient(#db5c39, #ff8b0f);
  padding: 3px;
  margin: 1rem;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
}

.module {
  background: #222;
  height: 350px;
  border: 2px solid transparent;
  border-image: linear-gradient(#db5c39, #ff8b0f);
  border-image-slice: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  transition: all 0.3s ease-in-out;

  .module-overlay {
    height: 20%;
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: 2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: rgba(7, 16, 19, 0.7);
  }

  &:hover {
    transform: translateX(-10px) translateY(-10px);
    cursor: pointer;
  }
}
