.filler {
  height: 75px;
}
body{
  background-color: #071013;
  
  //box-shadow: 0 0 20px 1px  $primary-orange ; /* Glowing effect */
  
}

.section-header {
  text-align: center;
  cursor: default;

  .header-underline {
    height: 4px;
    width: 60px !important;
    background-color: #c1c1c1;
    // margin-left: auto;
    margin: 0 auto;
    text-align: center;
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    color: white;

    span {
      color: $primary-orange;
    }
  }
}
.desc{ 
  color: white;
}

.about-row {
  padding: 0rem 0;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: flex-start;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .about-us-header {
    font-size: 2rem;
    font-family: "Poppins";
    color:  $primary-orange;
  }

  .about-us-header-small {
    font-size: 1.4rem;
    color:  $primary-orange;
    font-family: "Poppins";
    @media only screen and (max-width: 768px) {
      padding-top: 1rem;
    }
  }

  .about-desc {
    flex: 6;
    z-index: 0;
    position: relative;
    padding: 0rem 2rem;
    font-size: 1rem;
    padding-right: 10rem;
    font-family: "Poppins";

    @media only screen and (max-width: 768px) {
      display: flex;
      padding: 1rem 2rem;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .image1 {
    background: url("../assets/AboutUs/image1.jpg");
    height: 18rem;
    margin: 1rem;
    width: 20rem;
    position: relative;
    max-width: 500px;
    background-position: center;
    flex: 5;
    background-size: cover;

    @media only screen and (max-width: 768px) {
      flex: none !important;
      margin-right: auto;
      margin-left: auto;
      height: 15rem;
      margin-top: 0rem;
    }
  }

  .imagebg {
    border: 2px solid black;
    transform: translate(2px, 2px, 0);
    z-index: -1;
    height: 18rem;
    width: 20rem;
    max-width: 500px;

    position: relative;
  }
}

.last {
  margin-bottom: 2rem;
  font-family: "Poppins";
  color: white;
}

.mission {
  margin-top: 1rem;
}
