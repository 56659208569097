

#main-container {
  position: relative;
  z-index: 2;
  background-color: #000;
}

.challenge-content,
.challenge-intro {
  margin: auto;
  width: 75%;
  text-align: left;
}
.challenge-intro {
  padding: 1em 0 1rem;
  padding-top: 75px;

}
.center-image{
  display:flex;
  justify-content:center; 
  align-items:center;    
  
}
.challenge-intro h1 {
  padding: 0 0 1rem;
  font-size:40px;
}
.challenge-content,
.challenge-content h2 {
  padding: 1rem 0;
}
.contact {
  text-align: center;
}
.contact span {
  text-align: right;
}
.contact p {
  padding: 0 3rem;
}
.intro {
  padding-top: 0;
  margin: auto;
}
.challenges a,
.challenges a:visited {
  color: #fe5831;
}
.challenges a:hover {
  color: #fe5831;
  font-weight: 600;
}
.note {
  font-weight: 700;
}
.embedded2-img,
.pcb-img {
  width: 50%;
}

.gif1{
  width: 110%;
  .container img {

    object-fit: cover; 
  } 
  
}
#mech-challenge img {
  width: 75%;
  padding: 1rem 2rem;
}
#sna-table {
  text-align: center;
}

.Accordian  { 
  background-color:#000;
  text-align: center;

}


