.projects-section {
  background-color: $secondary-black;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;

  .project-header {
    color: $primary-orange;
    font-size: 4rem;
    padding: 0;
    position: sticky;
    width: 100%;
    background: rgba($color: $secondary-black, $alpha: 1);
    box-shadow: 0 4px 16px rgba($color: $primary-black, $alpha: 0.2);
    top: 75px;
    text-align: center;
    z-index: 999;

    .header-underline {
      height: 4px;
      width: 60px !important;
      background-color: $primary-orange;
      // margin-left: auto;
      margin: 0 auto;
      transform: translateY(-14px) translateX(100px);
    }
  }

  .projects-container {
    width: 100%;
    display: flex;

    padding-bottom: 10rem;

    .pointer-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: sticky;
      height: 60vh;
      top: 25vh;
      justify-content: space-evenly;
      padding-left: 4rem;

      @media only screen and (max-width: 1400px) {
        flex: 2;
        padding-left: 3rem;
      }

      @media only screen and (max-width: 992px) {
        display: none;
      }

      .orbit {
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: 0.2s ease-in all;
        border: 1px solid rgba(255, 255, 255, 0);

        .pointer-description {
          position: absolute;
          font-size: 1.4rem;
          font-weight: bold;
          left: 7rem;
          white-space: nowrap;
          opacity: 0;
          transition: 0.2s ease-in-out all;

          @media only screen and (max-width: 1400px) {
            left: 6.5rem;
          }
        }
      }

      .orbit-active {
        border: 1px solid #fff;

        .pointer-description {
          opacity: 1 !important;
        }
      }

      .dot {
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .left-section {
      flex: 7;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media only screen and (max-width: 768px) {
        padding-bottom: 4rem;
      }

      .single-project {
        padding-top: 7rem;

        @media only screen and (max-width: 768px) {
          padding-top: 0;
        }
      }
    }

    .right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 8;

      @media only screen and (max-width: 768px) {
        display: none;
      }

      .right-content {
        min-height: 60vh;
        text-align: left;
        // background: pink;
      }

      .project-content {
        flex: 1;
        padding: 2rem;
        // padding-top: 0;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 3.5rem;
          font-family: $oswald;
          font-weight: 500;

          @media only screen and (max-width: 1024px) {
            & {
              font-size: 2.6rem;
            }
          }

          @media only screen and (max-width: 992px) {
            & {
              font-size: 2.4rem;
            }
          }

          @media only screen and (max-width: 768px) {
            & {
              font-size: 2rem;
            }
          }
        }

        p {
          font-size: 1.2rem;
          font-family: $montserrat;
          line-height: 2.5rem;
          margin-top: 1rem;

          @media only screen and (max-width: 1024px) {
            & {
              font-size: 1rem;
            }
          }

          @media only screen and (max-width: 768px) {
            & {
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .single-project-section {
      display: flex;
      padding: 2rem 0;

      @media only screen and (max-width: 576px) {
        flex-direction: column;
      }

      .left-section {
        padding: 0 2rem;

        flex: 1;
        display: flex;
        justify-content: center;
      }

      .project-content {
        flex: 1;
        padding: 1rem 2rem;

        h1 {
          font-size: 3.5rem;

          @media only screen and (max-width: 768px) {
            & {
              font-size: 2.4rem;
            }
          }
        }

        p {
          font-size: 1.4rem;

          @media only screen and (max-width: 768px) {
            & {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .parallax-div {
    width: 45vw;

    @media only screen and (max-width: 768px) {
      & {
        width: 80vw;
      }
    }
  }

  .parallax-inner {
    text-align: center;
  }

  .project-img {
    max-width: 100%;
    height: auto;
    padding: 1rem 0;
    width: 80%;
  }

  .project-name {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 3.5rem;
    left: 50%;
    font-family: $oswald;

    position: absolute;
    transform: translate(-50%, -6.7rem);
    background: rgba($color: $secondary-black, $alpha: 0.7);
    display: none;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  .project-description {
    width: 82%;
    height: 95%;
    margin: 0 auto;
    font-size: 1.2rem;
    padding: 0 2rem;
    left: 50%;
    top: 50%;
    text-align: left;
    position: absolute;
    // transform: translate(-50%, -52%);
    opacity: 0;
    transform: translate(-50%, 30%);
    z-index: -1;
    background: rgba($color: $secondary-black, $alpha: 0.8);
    display: none;
    transition: 0.2s ease-in-out all;

    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
  }

  .project-selected {
    display: none;

    @media only screen and (max-width: 768px) {
      transform: translate(-50%, -50%);
      display: flex;
      z-index: 2;
      opacity: 1;
    }
  }

  .project-description:hover {
    transform: translate(-50%, -52%);
    box-shadow: 8px 8px 10px rgba($color: $primary-orange, $alpha: 0.6);
  }
}

.orbit:hover {
  border: 3px solid rgba(255, 255, 255, 1);
}

.orbit:hover .right-section {
  display: none;
}
