.logo {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1000;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  img:nth-child(1) {
    display: none;
    position: absolute;
    left: 25%;
    top: 8rem;
    z-index: 1000;

    @media only screen and (max-width: 768px) {
      padding: 0 3rem;
    }
  }

  img:nth-child(2) {
    display: none;
    position: absolute;
    right: 23%;
    top: 10rem;
    z-index: 1000;
    @media only screen and (max-width: 768px) {
      padding: 0 3rem;
    }
  }

  img:nth-child(3) {
    width: 40%;
    top: 0;

    @media only screen and (max-width: 768px) {
      width: 80%;
    }
  }

  // img:nth-child(4) {
  //   width: 40%;
  //   top: 0;
  //   position: absolute;

  //   @media only screen and (max-width: 768px) {
  //     width: 80%;
  //   }
  // }
}

.landing-manas {
  background-color: $primary-black;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  canvas {
    z-index: -1 !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
