.contact-section {
  background-color: #071013;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
}

.contact-header {
  color: white;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 3rem;
}

.form {
  background-image: url("../assets/manipal-map.png");
  min-height: 100%;
  width: 90vw;
  border-radius: 25px;
}

form {
  padding-top: 1.8rem;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.7);
  color: #ff6f28;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.7);
  color: #ff6f28;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #c4c4c4;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container input {
  height: 3rem;
  border-radius: 4px;
  color: #c4c4c4;
  background-color: transparent !important;
  border: 1.5px solid #c4c4c4;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
  color: #c4c4c4;
}

.input-container input:focus {
  -webkit-box-shadow: 0px 0px 15px 2px rgba(255, 111, 40, 0.2);
  box-shadow: 0px 0px 15px 2px rgba(255, 111, 40, 0.2);
  border-bottom: 3px solid #ff6f28;
}

.error {
  color: red;
  position: absolute;
  margin-top: 3rem;
}

.message-error {
  color: red;
  position: absolute;
  margin-top: 7.5rem;
}

textarea {
  resize: none;
  background-color: transparent;
  height: 7.5rem;
  border-radius: 4px;
  color: #c4c4c4;
  border: 1.5px solid #c4c4c4;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

textarea label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #c4c4c4;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container textarea:focus .error-shown {
  -webkit-box-shadow: 0px 0px 15px 2px rgba(255, 111, 40, 0.2);
  box-shadow: 0px 0px 15px 2px rgba(255, 111, 40, 0.2);
  border-bottom: 3px solid #ff6f28;
}

.submit {
  width: 6rem;
  margin: auto;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1.5px solid #c4c4c4;
  border-radius: 4px;
  color: #c4c4c4;
  transition: all 0.3s ease;
}

.submit:hover  {
  color: #f49221;

}

.error-shown input {
  border: 1.5px solid red;
}

.error-shown input:focus {
  border: 1.5px solid #c4c4c4;
  border-bottom: 3px solid #ff6f28;
}

.error-shown textarea {
  border: 1.5px solid red;
}

.error-shown textarea:focus {
  border: 1.5px solid #c4c4c4;
  border-bottom: 3px solid #ff6f28;
}

@media (max-width: 574px) {
  form {
    padding: 2rem;
    margin-bottom: 30px;
  }
}
