.nav-container {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  color: black;
  height: auto;
  padding: 0.2rem 1rem;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
    padding: 0.4rem 1rem;
    font-size: smaller;
  }

  .manas-logo {
    background-image: url("../assets/Logos/manas-with-border.png");
    width: 100%;
    height: 64px;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: 400px) {
      display: flex;
      font-size: smaller;
      justify-content: center;
    }
  }

  .nav-links {
    display: flex;
    margin-left: auto;

    @media only screen and (max-width: 400px) {
      display: block;
      margin-left: 0;
      font-size: 400px;
      justify-content: center;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      white-space: nowrap;
      padding: 1rem 1.4rem;
      font-size: 1.4rem;

      @media only screen and (max-width: 400px) {
        justify-content: center;
        font-size: 400px;
      }
    }
  }
}

.errorpage-container {
  .error {
    background-color: #ffffff;
    line-height: 420%;
    width: 100%;
    position: relative;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: bold;
    margin: auto;
    margin-top: 4rem;
  }
  .error h1 {
    font-size: 100px;
    width: fit-content;
    margin: auto;
  }
  h1:nth-of-type(4n + 1) {
    color: #ededed;
  }
  h1:nth-of-type(2n) {
    color: #d8d8d8ec;
  }
  h1:nth-of-type(3) {
    color: #ff6f28;
  }
  .Button {
    margin-left: 47%;
    margin-top: 5%;
    color: black;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    background: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: all 1s;
  }
  .Button:after,
  .Button:before {
    content: " ";
    width: 10px;
    height: 10px;
    position: absolute;
    transition: all 1s;
  }
  .Button::after {
    top: -1px;
    left: -1px;
  }
  .Button-before {
    bottom: -1px;
    right: -1px;
  }
  .Button:hover {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .Button:hover:before,
  .Button:hover:after {
    width: 102%;
    height: 102%;
  }
  @media only screen and (max-width: 400px) {
    justify-content: center;
    font-size: smaller;
  }
}
