.teampage-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  .teampage-header-container {
    height: 56vh;
    margin-top: 75px;
    background-image: linear-gradient(
        180deg,
        rgba(218, 80, 44, 0.6) 0%,
        rgba(216, 149, 56, 0.6) 100%
      ),
      url("../assets/manipal2022_3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 47%;
    background-position-x: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    @media only screen and (max-width: 768px) {
      height: 20vh;
    }

    h1 {
      font-size: 3.6rem;
      text-align: center;

      @media only screen and (max-width: 768px) {
        font-size: 2.4rem;
      }
    }
  }

  .team {
    background: #f9f9f9;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      padding: 2rem 0rem 2rem 0;
    }

    span {
      font-size: 2.4rem;
      text-align: center;

      b {
        color: $primary-orange;
      }
    }

    .team-table {
      // padding: 2rem;
      flex-wrap: wrap;
      display: flex;

      justify-content: center;

      @media only screen and (max-width: 768px) {
        padding: 0;
      }

      .member {
        margin: 2rem;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        width: 13rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 1.5rem;
        border-radius: 8px;

        @media only screen and (max-width: 768px) {
          width: 7rem;
          padding: 0.5rem;
          margin: 1rem 0.2rem;
        }

        .member-name {
          padding: 0;
          font-size: 1.1rem;
          text-align: center;

          @media only screen and (max-width: 768px) {
            font-size: 0.7rem;
            padding-top: 0.3rem;
          }
        }

        .member-designation {
          color: $primary-orange;
          overflow-wrap: break-word;
          text-align: center;
          font-size: 0.9rem;
          @media only screen and (max-width: 768px) {
            font-size: 0.6rem;
          }
        }

        .profile-image {
          width: 7rem;
          height: 7rem;
          border-radius: 100px;
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% 200%;

          @media only screen and (max-width: 768px) {
            width: 3.5rem;
            height: 3.5rem;
          }
        }

        .profile-image:hover {
          background-position: 0 100%;
        }

        .icon-shown {
          display: inline-block;
          height: 20px;
          width: 20px;
          margin: 0 10px 10px 0;
          text-align: center;
          line-height: 40px;
          color: #000000;
          transition: all 0.3s ease;

          @media only screen and (max-width: 768px) {
            height: 5px;
            width: 5px;
            line-height: 10px;
            margin: 0.2rem;
            font-size: 6px;
          }
        }

        .icon-hidden {
          display: none;
        }

        .social-link a:nth-child(1):hover {
          color: #3b5998;
          transform: scale(1.2);
        }
        .social-link a:nth-child(2):hover {
          color: #d1c436;
          transform: scale(1.2);
        }
        .social-link a:nth-child(3):hover {
          color: #0072b1;
          transform: scale(1.2);
        }
        .social-link a:nth-child(4):hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.alumni h1 {
  text-align: center;
  margin: auto;
  margin: 3rem;

  span {
    color: $primary-orange !important;
  }
}

.present {
  font-size: 30px !important;
  font-weight: bold;
}

.present span {
  height: 15px !important;
  width: 15px !important;
  background-color: $primary-orange !important;
}

.dot-label {
  transition: 0.3s all ease;
}

.future span {
  background-color: #ffffff !important;
}

.past span {
  background-color: #ffffff !important;
  border: 2px solid $primary-orange !important;
}

.dot-label span {
  margin: auto !important;
  border-color: $primary-orange !important;
}

.alum-table {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    padding: 2rem 0rem;
  }

  .content {
    .alumni-member {
      margin: 2rem;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      width: 13rem;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem;
      transition: all 0.3s ease;
      border-radius: 8px;
      min-height: 17rem;

      @media only screen and (max-width: 768px) {
        width: 6rem;
        margin: 0.7rem 0.2rem;
        padding: 0.5rem;
        min-height: 7rem;
      }

      &:hover {
        filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
      }

      .alumni-name {
        padding: 0;
        font-size: 1.2rem;
        text-align: center;

        @media only screen and (max-width: 768px) {
          font-size: 0.5rem;
          padding-top: 0.3rem;
        }
      }

      .alumni-designation {
        color: $primary-orange;
        overflow-wrap: break-word;
        text-align: center;
        min-height: 3rem;

        @media only screen and (max-width: 768px) {
          font-size: 0.4rem;
          min-height: 0.8rem;
        }
      }

      .profile-image-2018,
      .profile-image-2019,
      .profile-image-2020,
      .profile-image-2021,
      .profile-image-2022,
      .profile-image-2023,
      .profile-image-2024 {
        width: 7rem;
        height: 7rem;
        border-radius: 100px;
        background-position: 100% 0;
        background-repeat: no-repeat;
        background-size: 100% 200%;

        @media only screen and (max-width: 768px) {
          width: 2rem;
          height: 2rem;
        }
      }
      // .profile-image-2018:hover,
      // .profile-image-2019:hover,
      // .profile-image-2020:hover,
      // .profile-image-2021:hover,
      // .profile-image-2022:hover,
      // .profile-image-2023:hover {
      //   background-position: 0 100%;
      // }
      .profile-image-2014,
      .profile-image-2015,
      .profile-image-2016,
      .profile-image-2017 {
        width: 7rem;
        height: 7rem;
        border-radius: 100px;
        background-position: 100% 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @media only screen and (max-width: 768px) {
          width: 2rem;
          height: 2rem;
        }
      }

      .icon-shown {
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 10px 10px 0;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        color: #000000;
        transition: all 0.3s ease;

        @media only screen and (max-width: 768px) {
          height: 5px;
          width: 5px;
          line-height: 10px;
          font-size: 6px;
        }
      }

      .icon-hidden {
        display: none;
      }

      .social-link a:nth-child(1):hover {
        color: #3b5998;
        transform: scale(1.2);
      }
      .social-link a:nth-child(2):hover {
        color: #d1c436;
        transform: scale(1.2);
      }
      .social-link a:nth-child(3):hover {
        color: #0072b1;
        transform: scale(1.2);
      }
      .social-link a:nth-child(4):hover {
        transform: scale(1.2);
      }
    }
  }
}

.timeline {
  width: 60%;
  height: 100px;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.timeline-mobile {
  display: none;
  .disabled {
    color: #c1c1c1;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 2rem;
    color: $primary-orange;
  }
}

.mentor-image {
  width: 7rem;
  height: 7rem;
  border-radius: 100px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;

  @media only screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
}

.social-link {
  justify-content: center;
  align-items: center;
}
