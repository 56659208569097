* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mukta", sans-serif;
  scroll-behavior: smooth;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $primary-black;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-orange;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $primary-orange, $alpha: 1);
  }
}
